<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-dialog
            v-model="isUpdating" height="700" width="700" @click:outside="()=>{
                this.isUpdating = false;
                this.isUpdatingId = null;
            }">
        <ComponentUpdateCompanyFunnel
            @finish="fetch()"
            v-if="isUpdating"
            @close="()=>{
                this.isUpdating = false;
                this.isUpdatingId=null;
            }"
            :id="this.isUpdatingId"></ComponentUpdateCompanyFunnel>
        </v-dialog>

        <v-dialog
            v-model="isAdding" height="700" width="700" @click:outside="()=>{
                this.isAdding = false;
            }">
            <ComponentAddCompanyFunnel
            @finish="fetch()"
            v-if="isAdding"
            @close="()=>{
                this.isAdding = false;
                this.isAddingCompanyId 
            }"
            :companyId="this.$router.history.current.params.id"
            />
        </v-dialog>
        <div
            v-if="data!=null && !api.isLoading">
            <div
                class="d-flex justify-space-between">
                <div
                    class="text-h6 " style="font-weight:600">
                    Funnel
                </div>
    
                <div>
                    <v-btn color="primary" @click="openNewModal">
                        Add
                    </v-btn>
                </div>
            </div>
            <div>
                <v-row
                    v-for="(item,index) in this.data"
                    :key="index">
                    <v-col
                    class="pb-3"
                    ><br>
                    <div
                        class="my-2">
                        <div
                            class="d-flex justify-space-between">
                            <p class="text-h8">
                                Funnel Event Date :
                            </p>
                            <p v-if='item.funnel_event_date!=null'>
                                {{ convertTimeToDay(item.funnel_event_date) }}
                            </p>
                            <p
                                v-else>
                                -
                            </p>
                        </div>
                        <div
                            class="d-flex justify-space-between">
                            <p
                                class="text-h8">
                                Lead Source :
                            </p>
                            <p>
                                {{ (item.lead_source)?item.lead_source:'-' }}
                            </p>
                        </div>
                        <div
                            class="d-flex justify-end">
                            <v-btn
                                @click="openUpdateModal(item.id)"
                                color="primary">
                                Update
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                </v-row>
            </div>

        </div>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentAddCompanyFunnel from '../../components/form/ComponentAddCompanyFunnel.vue';
import ComponentUpdateCompanyFunnel from '../../components/form/ComponentUpdateCompanyFunnel.vue';
export default {
    components:{
    ComponentAddCompanyFunnel,
    ComponentUpdateCompanyFunnel
},
    computed: mapState({
    //
    }),
    props:[
        'companyId'
    ],
    data: () => ({
        isAdding:null,
        isAddingCompanyId:null,
        isUpdatingId:null,
        isUpdating:false,
        data:null,
        dataHeader:[
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getCompanyFunnelByCompany") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.isAdding = false;
            this.isAddingCompanyId = null;
            this.isUpdating = false;
            this.isUpdatingId = null;
            let fetchCompanyFunnelApi = this.fetchCompanyFunnel();
            this.$api.fetch(fetchCompanyFunnelApi);
        },
        fetchCompanyFunnel() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/funnel/company/detail/"+this.companyId;
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeToDay(time) {
            return this.$moment(time).format("LL");
        },
        openNewModal() {
            this.isAddingCompanyId = this.$router.history.current.params.id;
            this.isAdding = true;
        },
        openUpdateModal(id) {
            this.isUpdatingId = id;
            this.isUpdating = true;
        }
    }
}
</script>