<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-dialog
            v-model="isUpdating" height="700" width="700" @click:outside="()=>{
                this.isUpdating = false;
                this.isUpdatingId = null;
            }">
            <ComponentUpdateFinance
            @finish="fetch()"
            :id="this.isUpdatingId"
            v-if="this.isUpdating"
            @close="() => {
                this.isUpdating = false;
                this.isUpdatingId = null;
            }"
            />
        </v-dialog>

        <v-dialog
            v-model="isAdding" height="700" width="700" @click:outside="()=>{
                this.isAdding = false;
            }">
            <ComponentAddFinance
            @finish="fetch()"
            v-if="isAdding"
            @close="()=>{
                this.isAdding = false;
            }"
            :id="this.$router.history.current.params.id"/>
        </v-dialog>
        <div
            v-if="data!=null && !api.isLoading">
            <div
                class="d-flex justify-space-between mt-2">
                <div
                    class="text-h6 " style="font-weight:600">
                    Financial detail
                </div>
    
                <div>
                    <v-btn color="primary" @click="openNewModal">
                        Add
                    </v-btn>
                </div>
            </div>
            <div>
                <v-row
                    v-for="(item,index) in this.data"
                    :key="index">
                    <v-col
                    class="pb-3"
                    >
                    <div
                        class="my-2">
                        <div 
                            class="d-flex justify-space-between">
                            <p 
                                class="text-h8">
                                Financial year end
                            </p>
                            <p>
                                {{ item.fye }}
                            </p>
                        </div>
                        <div
                            class="d-flex justify-space-between">
                            <p class="text-h8">
                                Revenue (RM'000)
                            </p>
                            <p >
                            {{ (item.revenue!=null)?item.revenue:'-' }} 
                            </p>
                        </div>
                       
                        <div
                            class="d-flex justify-space-between">
                            <p
                                class="text-h8">
                                Profit before tax (RM'000)
                            </p>
                            <p>
                                {{ (item.pbt)?item.pbt:'-' }}
                            </p>
                        </div>
                        <div
                            class="d-flex justify-space-between">
                            <p
                                class="text-h8">
                                Profit after tax (RM'000)
                            </p>
                            <p>
                                {{ (item.pat)?item.pat:'-' }}
                            </p>
                        </div>
                        <div
                            class="d-flex justify-end">
                            <v-btn
                                @click="openUpdateModal(item.id)"
                                color="primary">
                                Update
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                </v-row>
            </div>

        </div>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentUpdateFinance from '../form/ComponentUpdateFinance.vue';
import ComponentAddFinance from '../form/ComponentAddFinance.vue';
export default {
    components:{
    ComponentUpdateFinance,
    ComponentAddFinance
},
    computed: mapState({
    //
    }),
    props:[
        'companyId'
    ],
    data: () => ({
        isAdding:null,
        isAddingCompanyId:null,
        isUpdatingId:null,
        isUpdating:false,
        data:null,
        dataHeader:[
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getListOfFinanceSurvey") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.isAdding = false;
            this.isAddingCompanyId = null;
            this.isUpdating = false;
            this.isUpdatingId = null;
            
            this.$api.fetch(this.fetchCompanyFinance());
        },
        fetchCompanyFinance() {
           let tempApi = this.$_.clone(this.api);
           tempApi.method ="GET";
           tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/survey/finance?id="+this.companyId;
           return tempApi; 
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeToDay(time) {
            return this.$moment(time).format("LL");
        },
        openNewModal() {
            this.isAddingCompanyId = this.$router.history.current.params.id;
            this.isAdding = true;
        },
        openUpdateModal(id) {
            this.isUpdatingId = id;
            this.isUpdating = true;
        }
    }
}
</script>