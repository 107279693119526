<style scoped>
hr {
    border-color: hsl(0, 1%, 55%);
    opacity: 20%;
}
</style>

<template>
    <v-container>
        <ANotAllowed :notAllowed="notAllowed" @close="closeNotAllowed" />
        <ANotAllowed :notAllowed="notAllowedAction" @close="closeNotAllowedAction" />
        <AConfirmation :isShow="isPendingVerification" @confirm="updateCompanyVerification"
            @cancel="cancelUpdateCompanyVerification" />
        <AConfirmation :isShow="isPendingRefundStatus" @confirm="updateRefundStatus" @cancel="cancelUpdateRefundStatus" />
        <AWarning :message="warningNoSubscription" v-if="this.subscription == null && this.isLoadingSubscription == false">
        </AWarning>
        <AError :api="this.api" />
        <ASuccessWithoutReload :api="this.api" @close="() => { api.isSuccesful = false; }" />
        <!-- <v-dialog
        v-model="remark">
            <mavon-editor
            v-model = "remarkNotes"
            placeholder = "Taking notes...">
            </mavon-editor>
            <v-toolbar>
                <v-btn
                color="primary">
                    Confirm
                </v-btn>
                <v-btn
                plain
                @click="close">
                    Cancel
                </v-btn>
            </v-toolbar>
        </v-dialog>
        -->
        <div class="d-flex justify-end">
            <v-menu offset-y style="padding:0px" v-model="actionDrop">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn min-width="150px" min-height="50px" color="primary" v-bind="attrs" v-on="on">
                        <!-- {{ on }} -->
                        <!-- {{ attrs }} -->
                        Actions
                        <v-icon v-if="actionDrop">
                            mdi-arrow-up
                        </v-icon>
                        <v-icon v-else>
                            mdi-arrow-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <div v-for="(item, index) in Actions" :key="index">
                        <v-list-item v-if="item.rolesAllowed.some(role => $store.getters.getRole.includes(role))"
                            @click="actions(item.action)">
                            <v-icon class="mx-2" size="20">
                                {{ item.icon }}
                            </v-icon>
                            <div>
                                {{ item.name }}
                            </div>
                        </v-list-item>
                        <v-list-item v-else @click="showNotAllowed">
                            <v-icon class="mx-2" size="20">
                                {{ item.icon }}
                            </v-icon>
                            <div>
                                {{ item.name }}
                            </div>
                        </v-list-item>

                    </div>
                </v-list>
            </v-menu>
        </div>
        <v-skeleton-loader class="mt-4" ref="skeleton" type="table" v-if="isLoadingCompany">
        </v-skeleton-loader>
        <div class="mt-2" v-if="!isLoadingCompany">
            <v-toolbar color="secondary">
                <div class="white--text text-h5">
                    Company
                </div>
            </v-toolbar>
            <v-card v-if="this.company" class="pa-5">
                <v-row no-gutters>
                    <v-col class="pr-5" cols="12" sm="6">
                        <div class="mx-2">
                            <div class="d-flex justify-start  text-h6 my-4" style="font-weight: 600;">
                                Company Detail
                            </div>

                            <div class="d-flex justify-space-between">
                                <p class="text-h7">
                                    Name :
                                </p>
                                <p class="text-h7">
                                    {{ company.name }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between"
                                v-if="this.allowedRoleUpdateStatus.some(role => this.$store.getters.getRole.includes(role))">
                                <p class="text-h7">
                                    Stripe :
                                </p>
                                <p>
                                    {{ (company.company_stripe_id != null) ? company.company_stripe_id : null }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-h7">
                                    SSM :
                                </p>
                                <p class="text-h7">
                                    {{ (company.ssm_no) ? company.ssm_no : '-' }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-h7">
                                    Address :
                                </p>
                                <p class="text-h7">
                                    {{ (company.address) ? company.address : '-' }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-h7">
                                    Industry :
                                </p>
                                <p class="text-h7">
                                    {{ (company.industry) ? company.industry : '-' }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-h7">
                                    AAE :
                                </p>
                                <p class="text-h7">
                                    {{ (company.minutes != null) ? company.minutes + " Minutes" : '-' }}
                                </p>
                            </div>
                            <div
                                v-if="this.allowedRoleUpdateStatus.some(role => this.$store.getters.getRole.includes(role))">
                                <div class="d-flex justify-space-start">
                                    <p class="text-h7">
                                        Status :
                                    </p>

                                </div>
                                <div class="d-flex justify-space-start">

                                    <v-progress-circular v-if="isLoadingUpdateVerification" :size="50" color="primary"
                                        indeterminate></v-progress-circular>
                                    <v-select v-else @change="validateInputChangeVerification" dense outlined
                                        v-model="company.verification" :items="companyVerificationOption">

                                    </v-select>
                                </div>

                            </div>
                            <div>
                                <hr />
                                <v-tabs style="{color:#33BDF1}" class="my-3">
                                    <v-tab>
                                        Client
                                    </v-tab>
                                    <v-tab>
                                        PIC
                                    </v-tab>
                                    <v-tab-item>
                                        <div class="my-2">
                                            <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                                                <v-carousel-item v-for="(item, index) in client" :key="index">
                                                    <div class="mx-2">
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Name :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.fname }} {{ item.lname }}
                                                            </p>
                                                        </div>

                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Position :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ (item.position) ? item.position : "-" }}
                                                            </p>
                                                        </div>
                                                        <div v-if="checkIfCanAccessBonusOffer()"
                                                            class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Account category :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ (item.acc_category) ? item.acc_category : "-" }}
                                                            </p>
                                                        </div>
                                                        <div v-if="checkIfCanAccessBonusOffer()"
                                                            class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Bonus offer :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ (item.bonus_offer) ? item.bonus_offer : "-" }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Phone no :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.mobile }}
                                                            </p>

                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Email :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.email }}
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-space-between">
                                                            <p 
                                                                class="text-h7">
                                                                Existing client :
                                                            </p>
                                                            <p
                                                                v-if="item.existing_client!=null">
                                                                {{ item.existing_client }}
                                                            </p>
                                                            <p v-else>
                                                                -
                                                            </p>

                                                        </div>
                                                        <div
                                                            class="d-flex justify-space-between">
                                                            <p  
                                                                class="text-h7">
                                                                Active :
                                                            </p>

                                                            <p
                                                                class="text-h7"
                                                                v-if="item.active!=null">
                                                                {{item.active}}
                                                            </p>
                                                            <p
                                                                v-else>
                                                                -
                                                            </p>
                                                        </div>

                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Gender :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ (item.gender) ? item.gender : "-" }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p>
                                                                Referral code
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.referral_uuid }}
                                                            </p>

                                                        </div>

                                                    </div>
                                                </v-carousel-item>
                                            </v-carousel>
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <div>
                                            <v-carousel :continuous="false" hide-delimiters height="auto" width="auto"
                                                v-if="(staff != null)">
                                                <v-carousel-item v-for="(item, index) in staff" :key="index">
                                                    <v-card class="pa-3">
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Name :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.fname }} {{ item.lname }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Status :
                                                            </p>
                                                            <p class="text-h7" v-if="item.deleted_at == null">
                                                                Active
                                                            </p>
                                                            <p v-else>
                                                                Inactive
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Phone no :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.mobile }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Email :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.email }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Role :
                                                            </p>
                                                            <p class="text-h7">
                                                                {{ item.role_name.replaceAll('_', ' ') }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-space-between">
                                                            <p class="text-h7">
                                                                Assigned Date
                                                            </p>
                                                            <p class="text-h7">
                                                                <span v-if="item.pic_created_at != null">
                                                                    {{ convertTimeZone(item.pic_created_at) }}

                                                                </span>
                                                            </p>
                                                        </div>
                                                    </v-card>
                                                </v-carousel-item>
                                            </v-carousel>
                                            <v-row
                                            v-else>
                                                <v-col
                                                class="mt-2">
                                                <AWarning
                                                message="No Person-in-charge assigned"/>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-tab-item>
                                </v-tabs>
                            </div>
                        </div>
                        <hr/>
                        
                        <div>
                            <!-- For Payment method section -->
                            <div
                            class="d-flex justify-start  mb-3">
                                <div class="text-h6 my-4 mb-3" style="font-weight:700">
                                    Payment method
                                </div>
                            </div>
                            <div class="d-flex justify-start">
                                <p class="text-h7 font-weight-bold">
                                    Credit card
                                </p>
                                
                            </div>
                            <div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Brand :
                                    </p>
                                    <p class="text-h7">
                                        {{ (company.card_brand)?company.card_brand:'-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Last 4 digits :
                                    </p>
                                    <p class="text-h7">
                                        {{ (company.card_last_four)?company.card_last_four:'-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Expired at :
                                    </p>
                                    <p class="text-h7">
                                        {{ (company.expired)?company.expired:'-' }}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <hr/>

                        <div>
                            <div class="mb-4">
                                <div class="text-h6 d-flex justify-start mt-4 mb-6   mb-3" style="font-weight:600">
                                    Last subscription from Stripe
                                </div>
                            <div v-if="this.subscription!=null">
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Name :  
                                    </p>
                                    <p class="text-h7">
                                        <!-- {{ subscription.name }} {{ subscription.plan.name2 }} -->
                                        {{ subscription.plan_name }}
                                    </p>
                                </div>



                                        <div class="d-flex justify-space-between">
                                            <p class="text-h7">
                                                Price :
                                            </p>
                                            <p class="text-h7 text-decoration-line-through"
                                                v-if="(subscription.total_discount != null)">
                                                RM {{ subscription.price }}
                                            </p>
                                        </div>
                                        <div class="d-flex justify-space-between"
                                            v-if="subscription.total_discount != null">
                                            <p class="text-h7">
                                                Discount :
                                            </p>
                                            <p class="text-h7">
                                                <!-- RM {{ (subscription.price - subscription.amount_paid.slice(0,-2)) }} -->
                                                RM {{
                                                    (subscription.total_discount != 0) ? subscription.total_discount.slice(0,
                                                        -2) : 0
                                                }}
                                            </p>
                                        </div>
                                        <div class="d-flex justify-space-between"
                                            v-if="subscription.total_discount != null">
                                            <p class="text-h7">
                                                Total price :
                                            </p>
                                            <p class="text-h7 font-weight-bold">
                                                <!-- RM {{ (subscription.amount_paid!=0)?subscription.amount_paid.slice(0,-2):0 }} -->
                                                {{ (subscription.amount_paid != 0) ?
                                                    "RM" + subscription.amount_paid.slice(0, -2) : "Non - Stripe Payment" }}
                                            </p>
                                        </div>
                                        <!-- <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Invoice :
                                    </p>
                                    <p  class="text-h7">
                                        {{ (subscription.coupon==='da9dMVrn')?" Not issued":"Issued" }}
                                    </p>

                                </div> -->
                                        <div class="d-flex justify-space-between">
                                            <p class="text-h7">
                                                Referral Code :
                                            </p>
                                            <p class="text-h7">
                                                {{ (subscription.coupon) ? subscription.coupon : "Not applicable" }}
                                            </p>
                                        </div>
                                        <div class="d-flex justify-space-between">
                                            <p class="text-h7">
                                                Subscription date :
                                            </p>
                                            <p class="text-h7">
                                                <!-- {{ subscription.paid_at.split(' ')[0]}} -->
                                                {{ convertTimeZone(subscription.created_at) }}
                                            </p>
                                        </div>
                                        <div class="d-flex justify-space-between"
                                            v-if="subscription.refund_status != 'Canceled'">
                                            <p class="text-h7">
                                                Next renewal date :
                                            </p>
                                            <p class="text-h7">
                                                <!-- {{ parseInt((subscription.invoice.paid_at.split(' ')[0]).split('-')[0])+1  }}-{{ (subscription.invoice.paid_at.split(' ')[0]).split('-')[1] }}-{{ (subscription.invoice.paid_at.split(' ')[0]).split('-')[2] }}
                                         -->

                                                {{ convertTimeZone(subscription.renewal_at) }}
                                            </p>
                                        </div>
                                        <div class="d-flex justify-end">
                                            <v-btn class="text-h10" color="primary" @click="readAllSubscription">
                                                Show all
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span>No subscription found</span>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <!-- <div>
                            <div
                                class="d-flex justify-start mb-3">
                                <div
                                    class="text-h6 my-5 mb-3"
                                    style="font-weight:700">
                                    Subscription history
                                    
                                </div>
                            </div>
                            <div
                                v-if="subscriptionHistory!=null">
                                <v-row
                                    v-for="(item,index) in this.subscriptionHistory"
                                    :key="index">
                                    <v-col
                                        class="pb-3"
                                        >
                                        <div
                                            class="my-2">
                                            <div 
                                                class="d-flex justify-start">
                                                <p 
                                                    class="text-h7" style="font-weight:600">
                                                    Year {{ item.year }}
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-space-between">
                                                <p class="text-h8">
                                                    Status
                                                </p>
                                                <p >
                                                {{ (item.status!=null)?item.status:'-' }} 
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-space-between">
                                                <p class="text-h8">
                                                    Payment date
                                                </p>
                                                <p v-if='item.payment_date!=null'>
                                                    {{ convertTimeToDay(item.payment_date) }}
                                                </p>
                                                <p
                                                    v-else>
                                                    -
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-space-between">
                                                <p
                                                    class="text-h8">
                                                    Subscription start date 
                                                </p>
                                                <p v-if="item.subscription_start_date!=null">
                                                    {{ convertTimeToDay(item.subscription_start_date) }}
                                                </p>
                                                <p 
                                                    v-else>
    
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-space-between">
                                                <p
                                                    class="text-h8">
                                                    Subscription end date 
                                                </p>
                                                <p
                                                    v-if="item.subscription_end_date!=null">
                                                    {{ convertTimeToDay(item.subscription_end_date) }}
                                                </p>
                                                <p
                                                    v-else>
                                                -
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-space-between">
                                                <p
                                                    class="text-h8">
                                                    Salesperson in charge 
                                                </p>
                                                <p>
                                                    {{ item.pic_salesperson}}
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-space-between">
                                                <p
                                                    class="text-h8">
                                                    Commision release date
                                                </p>
                                                <p>
                                                    {{ (item.commision!=null)?convertTimeToDay(item.commision):'-'}}
                                                </p>
                                            </div>
                                            

                                            <div
                                                class="d-flex justify-space-between">
                                                <p
                                                    class="text-h8">
                                                    Offer
                                                </p>
                                                <p>
                                                    {{ (item.offer!=null)?item.offer:'-'}}
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-end">
                                                <v-btn
                                                    color="primary">
                                                    Update
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>

                            </div>
                        </div> -->
                        <div
                            v-if="checkIfCanAccessCompanyFinance()">
                            <ComponentFinance :companyId="this.$router.history.current.params.id"/>
                        </div>
                        <div
                        v-if="checkIfCanAccessSubscriptionHistory()">
                            <ComponentSubscriptionHistory
                                :companyId="this.$router.history.current.params.id"/> 
                            <hr/>
                        </div>
                        <div v-if="checkIfCanAccessCompanyRefund()">
                            <ComponentCompanyRefund :companyId="this.$router.history.current.params.id"/>
                            <hr/>
                        </div>
                        <div v-if="checkIfCanAccessCompanySales()">
                            <ComponentCompanySales :companyId="this.$router.history.current.params.id"/>
                            <hr/>
                        </div>
                        <div v-if="checkIfCanAccessCompanyHRDC()">
                            <ComponentCompanyHRDC :companyId="this.$router.history.current.params.id"/>
                            <hr/>
                        </div>
                        <div v-if="checkIfCanAccessCompanyAAE()">
                            <ComponentCompanyAAE :companyId="this.$router.history.current.params.id"/>
                            <hr/>
                        </div>
                        <div v-if="checkIfCanAccessCompanyFunnel()">
                            <ComponentCompanyFunnel :companyId="this.$router.history.current.params.id"/>
                            <hr/>
                        </div>
                        <div>
                            <!-- For Payment method section -->
                            <div
                            class="d-flex justify-start  mb-3">
                                <div class="text-h6 my-4 mb-3" style="font-weight:700">
                                    Payment method
                                </div>
                            </div>
                            <div class="d-flex justify-start">
                                <p class="text-h7 font-weight-bold">
                                    Credit card
                                </p>
                                
                            </div>
                            <div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Brand :
                                    </p>
                                    <p class="text-h7">
                                        {{ (company.card_brand)?company.card_brand:'-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Last 4 digits :
                                    </p>
                                    <p class="text-h7">
                                        {{ (company.card_last_four)?company.card_last_four:'-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Expired at :
                                    </p>
                                    <p class="text-h7">
                                        {{ (company.expired)?company.expired:'-' }}
                                    </p>
                                </div>
                            </div>

                        <hr/>
                        <div
                            v-if="checkIfCanAccessCompanyInvoice()">
                            <ComponentInvoiceCompany :companyId="this.$router.history.current.params.id" />
                        </div>
                        <hr/>
                      
                    </div>
                </v-col>
                <v-col
                class="pl-5"
                cols="12"
                sm="6">
                    <v-skeleton-loader
                    class="ma-4"
                    ref="skeleton"
                    type="card-avatar"
                    v-if="this.isLoadingSubscription">
                    </v-skeleton-loader>
                    <div class="mx-2">
                            <!-- <div 
                            v-if="specialRemark!=null && specialRemark.remark!=''"
                                class="d-flex justify-start text-h6 my-4 mb-3"
                                style="font-weight:600">
                                Special Remark
                            </div>
                            <div v-if="specialRemark != null && specialRemark.remark != ''" class="pa-2">
                                <v-skeleton-loader v-if="isLoadingSpecialRemark" ref="skeleton" type="card-avatar">

                                </v-skeleton-loader>
                                <v-carousel
                                    style="height:auto"
                                    max=5
                                    class="pa-3"
                                    :continuous="false"
                                    hide-delimiters>

                                </v-carousel>
                                <v-textarea background-color="amber lighten-4" stlye="font-color:black" outlined
                                    width="auto" auto-grow height="120" :readonly="readOnly" v-model="specialRemark.remark">
                                </v-textarea>
                                <div class="d-flex justify-end ">
                                    <v-btn
                                        :to="{ name: 'PageCompaniesViewRemark', params: { id: specialRemark.company_id } }"
                                        color="primary">
                                        View More
                                    </v-btn>
                                </div>
                            </div> -->
                            <ComponentPrototypeCompanySpecialRemark/>
                            <div class="d-flex justify-start  text-h6 my-4 mb-3" style="font-weight:600">
                                Renewal update status
                            </div>
                            <div v-if="renewalStatus != null">
                                <div class="pa-2"
                                    style="border-radius:8px;border-style: solid;border-color: rgba(5, 123, 166, 0.2);">
                                    <v-skeleton-loader class="ma-4" ref="skeleton" type="card-avatar"
                                        v-if="isLoadingRenewal">
                                    </v-skeleton-loader>
                                    <v-carousel style="height:auto" max=5 class="pa-3" :continuous="false" hide-delimiters
                                        max-height="350px" max-width="300px">
                                        <div class="d-flex justify-start mb-2">
                                            <div class="font-weight-bold text-h7">
                                                {{ this.totalRenewalStatus }} renewal update (2023)
                                                {{ }}
                                            </div>

                                        </div>
                                        <v-card color="white" v-if="!isLoadingRenewal" class="pa-2  elevation-0"
                                            height="auto" max-height="400px">

                                            <v-carousel-item v-for="(item, index) in renewalStatus" :key="index">
                                                <v-sheet v-if="index < 5">
                                                    <div class="d-flex justify-start pa-2">
                                                        {{ (index + 1) }}
                                                    </div>
                                                    <div class="d-flex justify-space-between">
                                                        <p>
                                                            Report by :
                                                        </p>
                                                        <p>
                                                            {{ item.fname }} {{ item.lname }}
                                                        </p>
                                                    </div>
                                                    <div class="d-flex \justify-space-between">
                                                        <p>
                                                            Report date :
                                                        </p>
                                                        <p>
                                                            {{ convertTimeZone(item.created_at) }}
                                                            <!-- {{ item.created_at.split('T')[0] }} -->
                                                        </p>
                                                    </div>
                                                    <div class="d-flex justify-space-between mb-2">
                                                        <div>
                                                            Renewal status :
                                                        </div>
                                                        <div>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-chip v-bind="attrs" v-on="on"
                                                                        color="pending white--text"
                                                                        v-if="item.renewal_status == 'Pending'">
                                                                        {{ item.renewal_status }}
                                                                    </v-chip>
                                                                    <v-chip v-bind="attrs" v-on="on"
                                                                        color="green white--text"
                                                                        v-if="item.renewal_status == 'Confirm'">
                                                                        {{ item.renewal_status }}
                                                                    </v-chip>
                                                                    <v-chip v-bind="attrs" v-on="on"
                                                                        color="green white--text"
                                                                        v-if="item.renewal_status == 'Confirm - Pending HRDC'">
                                                                        {{ item.renewal_status }}
                                                                    </v-chip>
                                                                    <v-chip v-bind="attrs" v-on="on" color="grey"
                                                                        v-if="item.renewal_status == 'Unreachable'">
                                                                        {{ item.renewal_status }}
                                                                    </v-chip>
                                                                    <v-chip v-bind="attrs" v-on="on" color="red white--text"
                                                                        v-if="item.renewal_status == 'Rejected'">
                                                                        {{ item.renewal_status }}
                                                                    </v-chip>
                                                                </template>
                                                                <span>
                                                                    {{ item.status }}
                                                                </span>
                                                            </v-tooltip>
                                                        </div>
                                                    </div>
                                                    <div v-if="item.confirm_at != null"
                                                        class="d-flex justify-space-between my-3">
                                                        <span>
                                                            Client payment date :
                                                        </span>
                                                        <span>
                                                            {{ convertTimeZone(item.confirm_at) }}
                                                        </span>

                                                    </div>

                                                    <div v-if="item.renewal_status == 'Pending' && item.next_followup_at != null"
                                                        class="d-flex justify-space-between my-2">
                                                        <span>
                                                            Next follow up date :
                                                        </span>
                                                        <span class="font-weight-bold">
                                                            {{ convertTimeZone(item.next_followup_at) }}
                                                        </span>
                                                    </div>
                                                    <div v-if="item.renewal_status == 'Rejected' && item.refund != null"
                                                        class="d-flex justify-space-between my-2">
                                                        <span>
                                                            Refund
                                                        </span>
                                                        <span class="font-weight-bold">
                                                            {{ (item.refund == "1") ? "Yes" : "No" }}
                                                        </span>
                                                    </div>
                                                    <p>
                                                        Remark :
                                                    </p>
                                                    <div style="overflow-y:auto">
                                                        <mavon-editor
                                                            style="min-height:100px;height:auto;width:100%;max-height:100px;overflow-y:auto"
                                                            width="auto" defaultOpen="preview" :toolbarsFlag="false"
                                                            :subfield="false" language="en" v-model="item.remark"
                                                            v-if="item.remark != null" :editable="false">
                                                        </mavon-editor>
                                                        <mavon-editor
                                                            style="z-index:0;min-height:100px;height:auto;width:100%"
                                                            height="auto" width="auto" defaultOpen="preview"
                                                            :toolbarsFlag="false" :subfield="false" language="en"
                                                            v-model="isRemarkNull" v-else :editable="false">
                                                        </mavon-editor>

                                                    </div>

                                                </v-sheet>
                                                <v-sheet v-else>
                                                    <div class="d-flex align-center justify-center"
                                                        style="margin-top: 25%;">
                                                        <v-btn color="primary" @click="readAllRenewalUpdate">Show
                                                            all</v-btn>
                                                    </div>
                                                </v-sheet>
                                            </v-carousel-item>
                                        </v-card>
                                    </v-carousel>
                                </div>
                                <div class="d-flex justify-end mt-2">
                                    <v-btn @click="readAllRenewalUpdate" color="primary">
                                        Show all
                                    </v-btn>
                                </div>
                            </div>
                            <div v-else>
                                <span class="d-flex justify-start text-h7">No renewal status update</span>
                            </div>
                            <hr class="my-7" />
                            <div class="my-3 mb-4">
                                <div class="d-flex justify-start text-h6 mb-6" style="font-weight:600">
                                    Refund status
                                </div>
                                <div>
                                    <v-skeleton-loader type="table" ref="skeleton" v-if="isRefund">

                                    </v-skeleton-loader>
                                </div>
                                <div v-if="refund != null && !isRefund" class="px-4"
                                    style="border-radius:8px;border-style: solid;border-color: rgba(5, 123, 166, 0.2);">
                                    <v-card class="elevation-0" flat max-height="400px" style="height:auto;width:auto;">
                                        <div>
                                            <v-carousel hide-delimiters style="height:auto" max="5" class="pa-3"
                                                :continuous="false" max-height="350px" max-width="300px">
                                                <v-carousel-item v-for="(item, index) in this.refund" :key="index">
                                                    <v-sheet>
                                                        <div class="d-flex justify-start pa-2">
                                                            {{ (index + 1) }}
                                                        </div>
                                                        <div class="d-flex justify-space-between my-4">
                                                            <div>
                                                                Issue by
                                                            </div>
                                                            <div>
                                                                {{ item.user_name }}
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-space-between my-4">
                                                            <div>
                                                                Issue Date
                                                            </div>
                                                            <div>
                                                                {{ convertTimeZone(item.created_at) }}
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-space-between my-4">
                                                            <div>
                                                                Refund Through :
                                                            </div>
                                                            <div>
                                                                {{ item.refund_type }}
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-space-between my-4"
                                                            v-if="item.refund_type == 'Bank Transfer'">
                                                            <div>
                                                                Bank
                                                            </div>
                                                            <div>
                                                                {{ item.bank_type
                                                                }}
                                                            </div>
                                                        </div>

                                                        <div class="d-flex justify-space-between my-4"
                                                            v-if="item.refund_type == 'Bank Transfer'">
                                                            <div>
                                                                Account No.
                                                            </div>
                                                            <div>
                                                                {{ item.bank_acc_no
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-space-between my-4"
                                                            v-if="item.refund_type == 'Bank Transfer'">
                                                            <div>
                                                                Account Holder
                                                            </div>
                                                            <div>
                                                                {{ item.bank_holder_name }}
                                                            </div>
                                                        </div>
                                                        <div v-if="isLoadingRefund == true">
                                                            <v-progress-circular v-if="isLoadingRefund" :size="50"
                                                                color="primary" indeterminate></v-progress-circular>
                                                        </div>
                                                        <div v-else>

                                                            <div class=""
                                                                v-if="allowedRoleUpdateStatus.some(role => $store.getters.getRole.includes(role))">

                                                                <div>
                                                                    Status :
                                                                </div>

                                                                <v-autocomplete
                                                                    @change="validateInputChangeRefundStatus(item.refund_id, item.status)"
                                                                    :items="refundOption" outlined dense
                                                                    v-model="item.status">

                                                                </v-autocomplete>
                                                            </div>

                                                            <div class="d-flex justify-space-between" v-else>
                                                                <div>
                                                                    Status
                                                                </div>
                                                                <div class="mt-3">

                                                                    <v-chip color="yellow" v-if="item.status == 'Pending'">
                                                                        {{ item.status }}

                                                                    </v-chip>
                                                                    <v-chip color="green white--text"
                                                                        v-if="item.status == 'Done'">
                                                                        {{ item.status }}
                                                                    </v-chip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-sheet>

                                                </v-carousel-item>

                                            </v-carousel>


                                        </div>

                                    </v-card>

                                </div>
                                <div v-else>
                                    <span class="d-flex justify-start text-h7">

                                        No refund recorded
                                    </span>
                                </div>
                            </div>
                            <hr class="my-6" />
                            <div class="my-5 py-4">
                                <div class="d-flex justify-start text-h6 mt-4 mb-6" style="font-weight: 600;">
                                    Latest payment status
                                </div>
                                <v-skeleton-loader class="ma-4" ref="skeleton" type="card-avatar" v-if="isLoadingPayment">
                                </v-skeleton-loader>
                                <div v-if="latestPaymentForm != null" class="py-4 px-7"
                                    style="border-radius:8px;border-style: solid;border-color: rgba(5, 123, 166, 0.2);">
                                    <div class="d-flex justify-space-between">
                                        <p>
                                            Report by :
                                        </p>
                                        <p>
                                            {{ latestPaymentForm.fname }} {{ latestPaymentForm.lname }}
                                        </p>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <p>Report date :</p>
                                        <p>
                                            {{ convertTimeZone(latestPaymentForm.created_at) }}
                                            <!-- {{ latestPaymentForm.created_at.split("T")[0]}} -->
                                        </p>
                                    </div>
                                    <div class="d-flex justify-space-between" v-if="latestPaymentForm.total_amount != null">
                                        <p>Payment date :</p>
                                        <p>
                                            {{ convertTimeZone(latestPaymentForm.payment_at) }}
                                            <!-- {{ latestPaymentForm.payment_at.split(" ")[0].replaceAll('/','-') }} -->
                                        </p>
                                    </div>
                                    <div class="d-flex justify-space-between" v-if="latestPaymentForm.total_amount != null">
                                        <p>Amount : </p>
                                        <p v-if="latestPaymentForm.total_amount != null">RM {{
                                            latestPaymentForm.total_amount }}</p>
                                    </div>
                                    <div class="d-flex justify-space-between"
                                        v-if="latestPaymentForm.payment_method != null">
                                        <p>Payment method : </p>
                                        <p>{{ latestPaymentForm.payment_method }}</p>
                                    </div>
                                    <!-- <div class="d-flex justify-space-between" v-if="latestPaymentForm.attachment_url!=null">
                                    <p>
                                        {{ latestPaymentForm.attachment_type }}
                                    </p>
                                    <a :href="latestPaymentForm.attachment_url" target="_blank" download>
                                        {{latestPaymentForm.attachment_name}}
                                    </a>
                                </div> -->
                                    <div class="d-flex justify-space-between">
                                        <p>Status : </p>
                                        <div>
                                            <v-chip class="black--text" color="yellow"
                                                v-if="latestPaymentForm.status == 'Pending'">
                                                {{ latestPaymentForm.status }}
                                            </v-chip>
                                            <v-chip class="white--text" color="green"
                                                v-if="latestPaymentForm.status == 'Success'">
                                                {{ latestPaymentForm.status }}
                                            </v-chip>
                                            <v-chip class="white--text" color="red"
                                                v-if="latestPaymentForm.status == 'Fail'">
                                                {{ latestPaymentForm.status }}
                                            </v-chip>
                                        </div>
                                    </div>
                                    <p>
                                        Remark
                                    </p>
                                    <mavon-editor
                                        style="z-index:0;max-height:200px; overflow-y:scroll;min-height:100px;height:auto;width:100%"
                                        height="auto" width="auto" defaultOpen="preview" :toolbarsFlag="false"
                                        :subfield="false" language="en" v-model="latestPaymentForm.remark"
                                        v-if="latestPaymentForm.remark != null" :editable="false">
                                    </mavon-editor>
                                    <mavon-editor
                                        style="z-index:0;min-height:100px;max-height:200px;overflow-y:scroll;height:auto;width:100%"
                                        height="auto" width="auto" defaultOpen="preview" :toolbarsFlag="false"
                                        :subfield="false" language="en" v-model="isRemarkNull" v-else :editable="false">
                                    </mavon-editor>
                                </div>
                                <div v-if="latestPaymentForm != null" class="d-flex justify-end mt-4">
                                    <v-btn @click="readAllPaymentUpdate" color="primary">
                                        Show all
                                    </v-btn>
                                </div>
                                <div v-if="!isLoadingPayment && latestPaymentForm == null">
                                    <span class="d-flex justify-start text-h7">No payment status update</span>
                                </div>
                            </div>
                        </div>
                        <hr class="my-8 " />
                        <div class="my-5">
                            <!-- <v-card class="pa-4 elevation-1" > -->

                            <div class="d-flex justify-start text-h6 my-4 " style="font-weight:600">
                                <span>
                                    Receipt & Invoices

                                </span>
                            </div>
                            <v-skeleton-loader v-if="isLoadingAttachment" ref="skeleton" type="table"></v-skeleton-loader>
                            <div v-if="Object.keys(attachment).length != 0" class="mt-5">
                                <div v-for="(item, index) in this.attachment" :key="index">
                                    <div v-if="index <= 2">
                                        <div class="d-flex justify-space-between">
                                            <div>
                                                <div class="d-flex flex-column">
                                                    <div>
                                                        <a :href="item.attachment_url" target="_blank" download
                                                            class="text-decoration-none">
                                                            {{ item.attachment_name }}

                                                        </a>

                                                    </div>
                                                    <div>
                                                        <span>
                                                            {{ "#" + (index + 1) }} {{ item.attachment_type }}
                                                        </span>

                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                {{ convertTimeZone(item.created_at) }}
                                            </div>

                                        </div>
                                        <hr style="color:#F2F3F7" class=" my-2" />

                                    </div>

                                </div>
                                <div class="d-flex justify-end pt-3">
                                    <v-btn color="primary"
                                        :to="{ name: 'PageAttachmentFormCompany', params: { 'id': this.$router.history.current.params.id } }">

                                        Show All
                                    </v-btn>
                                </div>
                            </div>
                            <div v-if="Object.keys(attachment).length == 0 && isLoadingAttachment == false">
                                <span class="d-flex justify-start text-h7">
                                    No Receipt or Invoices recorded
                                </span>
                            </div>
                            <!-- </v-card> -->
                        </div>
                        <!-- </div> -->
                    </v-col>
                </v-row>
                <hr class="mt-6">

                <v-row>
                    <v-col cols="12" sm="12" class="px-7">
                        <!-- <v-card class="mt-3"> -->
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-container>
</template>
<script>
import mavonEditor from 'mavon-editor';
import ComponentSubscriptionHistory from '../../components/company/ComponentSubscriptionHistory.vue';
import ComponentCompanyRefund from '../../components/company/ComponentCompanyRefund.vue';
import ComponentCompanySales from '../../components/company/ComponentCompanySales.vue';
import ComponentCompanyHRDC from '../../components/company/ComponentCompanyHRDC.vue';
import ComponentCompanyAAE from '../../components/company/ComponentCompanyAAE.vue';
import ComponentFinance from '../../components/company/ComponentFinance.vue';
import ComponentCompanyFunnel from '../../components/company/ComponentCompanyFunnel.vue';
import ANotAllowed from '../../components/common/ANotAllowed.vue';
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import ComponentInvoiceCompany from '../../components/invoice/ComponentInvoiceCompany.vue';
import ComponentPrototypeCompanySpecialRemark from '../../components/developer/prototype/ComponentPrototypeCompanySpecialRemark.vue';
export default{
    components:{
        'mavon-editor':mavonEditor.mavonEditor,
        ANotAllowed,
        ASuccessWithoutReload,
        ComponentSubscriptionHistory,
        ComponentCompanyRefund,
        ComponentCompanySales,
        ComponentCompanyHRDC,
        ComponentCompanyAAE,
        ComponentCompanyFunnel,
        AConfirmation,
        ComponentInvoiceCompany,
        ComponentFinance,
        ComponentPrototypeCompanySpecialRemark,
    },
    data:()=>({
        attachment:null,
        notAllowed:false,
        notAllowedAction:false,
        renewalStatus:[],
        totalRenewalStatus:null,
        isLoadingRefund:false,
        isLoadingUpdateVerification:false,
        isLoadingAttachment:false,
        isLoadingPayment:true,
        isLoadingRenewal:true,
        isLoadingSubscription:true,
        isLoadingSpecialRemark:true,
        isLoadingUser:true,
        isLoadingLog:true,
        warningNoSubscription:'Warning, This company does not subscribe to any plan',
        isLoadingCompany:true,
        isRemarkNull:"No remark recorded",
        remark:false,
        remarkNotes:null,
        refund:null,
        actionDrop:false,
        tempRefundId:null,
        tempRefundStatus:null,
        existing:null,
        active:null,
        role : null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        company: null,
        subscriptionHistory: null,
        companiesRefund: null,
        companySales: null,
        companyHRDC: null,
        latestRenewalForm: null,
        fetchSubscriptionHistory: null,
        laa: null,
        latestPaymentForm: null,
        latestPaymentStatusForm: null,
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        companyVerificationOption: ['valid', 'invalid', 'internal', 'unknown'],
        refundOption: ["Pending", "Done"],
        isPendingVerification: false,
        isPendingRefundStatus: false,
        isRefund: false,
        subscription: null,
        renewalDate: null,

        client: null,
        specialRemark: null,
        readOnly:true,
        staff: null,
        logs: null,
        Actions: [
            {
                name: 'Assign Task',
                icon: 'fa-edit',
                action: 'Followup',
                rolesAllowed: Array.from(["developer", "operation", 'salesperson', 'customer_service', 'vision_admin', 'sales_team_lead', 'sales_manager']),
            },
            {
                name: 'View company tasks',
                action: 'ViewTask',
                icon: 'bi-list-task',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin", "salesperson", "customer_service", "sales_team_lead", "sales_manager"]),
            },
            {
                name: 'Update renewal status',
                action: 'Renewal',
                icon: 'fa-clipboard',
                rolesAllowed: Array.from(["developer", 'customer_service', "salesperson", "vision_admin", "operation", "sales_team_lead", 'sales_manager'])
            },
            {
                name: 'Update payment status',
                action: 'Payment',
                icon: 'fa-clipboard',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation"])
            },
            // {
            //     name:'Request payment refund',
            //     action:'RequestRefund',
            //     icon:'fa-clipboard',
            //     rolesAllowed:Array.from(['developer','salesperson','customer_service','operation','vision_admin','sales_team_lead','sales_manager'])
            // },
            // 3:{
            //     name:'Request payment update',
            //     action:'RequestForm',
            //     icon:'fa-clipboard',
            //     rolesAllowed:Array.from(["developer",'customer_service','salesperson','operation','sales_team_lead']),
            // },

            {
                name: 'Upload proof of payment',
                action: 'UploadReceipt',
                icon: 'fa-receipt',
                rolesAllowed: Array.from(["developer", 'customer_service', 'salesperson', 'operation', 'vision_admin', 'sales_team_lead', 'sales_manager'])
            },
            {
                name: 'Upload invoice',
                action: 'UploadInvoice',
                icon: 'fa-receipt',
                rolesAllowed: Array.from(["developer", 'operation', 'vision_admin']),
            },
            {
                name: 'Add New User',
                action: 'AddNewUser',
                icon: 'fa-edit',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"]),
            },
            {
                name: 'Update company remark',
                icon: 'fa-edit',
                action: 'updateRemark',
                rolesAllowed: Array.from(["developer", "operation"]),
            },
            {
                name: 'Update company detail',
                icon: 'fa-edit',
                action: "Edit",
                rolesAllowed: Array.from(["developer", "vision_admin", "operation"]),
            },
            {
                name: 'Update user detail',
                icon: 'fa-edit',
                action: 'EditUser',
                rolesAllowed: Array.from(["developer", 'vision_admin', 'operation']),
            },
            {
                name: 'Swap user Company',
                icon: 'fa-edit',
                action: 'SwapUserCompany',
                rolesAllowed: Array.from(["developer"]),
            },
            {
                name: 'Update AAE minutes',
                icon: 'fa-edit',
                action: "AddMinutes",
                rolesAllowed: Array.from(["developer", "vision_admin", "operation"]),
            },
            {
                name: 'Update PIC',
                icon: 'fa-edit',
                action: 'PIC',
                rolesAllowed: Array.from(["developer", "operation"])
            },
            {
                name: 'Remove credit card',
                icon: 'fa-edit',
                action: 'RemoveCredit',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"])
            },
            {
                name: 'Send Whatsapp',
                icon: 'bi-whatsapp',
                action: 'SendWhatssap',
                rolesAllowed: Array.from(["developer", "operation", "salesperson", "vision_admin", "customer_service"]),
            },
            {
                name: 'Send Email',
                icon: 'fa-envelope',
                action: 'SendEmail',
                rolesAllowed: Array.from(['developer', 'operation', 'vision_admin']),
            },

            {
                name: 'Activate subscription',
                action: 'ActivateSubscription',
                icon: 'bi-file-text-fill',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"]),
            },
            {
                name: 'Cancel subscription',
                action: 'CancelSubscription',
                icon: 'bi-file-text-fill',
                rolesAllowed: Array.from(["developer", "operation", "vision_admin"]),
            },

            {
                name: 'View receipts & invoices',
                icon: 'fa-book',
                action: 'ViewAttachment',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation", "salesperson", "customer_service", "sales_team_lead", "sales_manager"]),
            },
            {
                name: 'View AAE minute record',
                icon: 'fa-book',
                action: 'AAEMinuteRecord',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation", "sales_manager"]),
            },
            {
                name: 'View log',
                icon: 'fa-book',
                action: 'Log',
                rolesAllowed: Array.from(["developer", "vision_admin", "operation", "salesperson", "customer_service", "sales_team_lead", "sales_manager"]),
            },



            // 3:{
            //     name:'Follow-up',
            //     action:'NameOfFunction',
            //     rolesAllowed:Array.from(["salesperson"])
            // }
        ]
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => 
        {
            if(resp.class==="getListOfActiveAndExistingSurvey") {
                this.existing = resp.data.existing_client;
                this.active = resp.data.active;
            }
            if(resp.class==="getSubscriptionHistoryByCompany") {
                this.subscriptionHistory = resp.data;
            }
            if (resp.class === "getCompanyRefundByCompany") {
                this.companiesRefund = resp.data;
            }
            if (resp.class === "getCompanyRemark") {
                this.specialRemark = null;
                if (resp.status !== "fail") {
                    this.specialRemark = resp.data;
                    const maxLength = 100;
                    this.specialRemark = resp.data.map(element=>{
                        if (element.remark.length > maxLength) {
                            this.specialRemark.remark = element.remark.slice(0, maxLength) + "...";
                        }
                        return element;
                    })
                    this.isLoadingSpecialRemark = false;
                }
            }
            if (resp.class == "getCompanyWithRestrict") {
                if (resp.status === 401) {
                    this.notAllowed = true;
                    return 1;

                }
                this.company = resp.data;
                this.isLoadingCompany = false;

            }

            if (resp.class === "getCompanyRefund") {
                this.refund = (Object.values(resp.data).length != 0) ? resp.data : null;
                this.isRefund = false;
                this.isLoadingRefund = false;
                return 1;
            }
            if (resp.class == "updateRefundStatus") {
                this.isLoadingRefund = false;
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Refund Status"
                return 1;
            }
            if (resp.class == "getAttachmentCompany") {
                this.attachment = resp.data;
                this.isLoadingAttachment = false;
            }
            if (resp.class == "getCompany") {
                this.company = resp.data;
                // this.latestPaymentForm = (resp.data.vision_latest_payment_status_form)?JSON.parse(resp.data.vision_latest_payment_status_form):null;
                this.isLoadingCompany = false;

            }
            if (resp.class == "readLatestForm") {
                this.isLoadingPayment = false;
                this.latestPaymentForm = resp.data;
            }
            if (resp.class == "getLatestSubscription") {
                this.subscription = resp.data;
                // let discount = this.$_.clone(this.subscription.total_discount);
                if(this.subscription!=null) {

                    this.subscription.total_discount = (this.subscription != null) ? String(this.$_.clone(this.subscription.total_discount)) : null;
                }
                this.isLoadingSubscription = false;
                return 1;
            }

            if (resp.class == "getLatestSubscriptionVersionTwo") {
                this.subscription.invoice = resp.data.invoice;
                this.subscription.plan = resp.data.plan;
                this.isLoadingSubscription = false;

            }

            if (resp.class == "getCompanyPICAndStaff") {
                this.client = (resp.data.client.length != 0) ? resp.data.client : null;
                this.staff = (resp.data.staff.length != 0) ? resp.data.staff : null;
                this.isLoadingUser = false;
            }
            if (resp.class == 'readFormWithLimit') {
                this.renewalStatus = (resp.data.form.data.length != 0) ? resp.data.form.data : null;
                this.totalRenewalStatus = resp.data.total;
                this.isLoadingRenewal = false;
                this.isLoadingRenewal = false;
            }
            if (resp.class == 'updateCompanyValidity') {
                this.isLoadingUpdateVerification = false;
            }
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchCompany(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/companies/" + companyId;
            return tempApi;
        },
        fetchCompanyWithRestrict(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/companies/restrict/id/" + companyId + "?pic_id=" + this.$store.getters.getUserId;
            return tempApi;
        },
        fetchCompanySubscriptionHistory(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/subscription_history/company/" + companyId;
            return tempApi;
        },
        fetchCompanyRefund(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/refund/company/detail/" + companyId;
            return tempApi;
        },
        fetchCompanySubscription(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            // tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription/company/"+companyId;
            tempApi.url = process.env.VUE_APP_SERVER_API + "/subscription/" + companyId + "/latest";
            return tempApi;
        },
        fetchClientPIC(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/clientpic/" + companyId;
            return tempApi;
        },
        fetchSpecialRemark() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/special_remark/company/" + this.$router.history.current.params.id;
            return tempApi;
        },
        fetchRefund() {
            this.isRefund = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/refund/company/" + this.$router.history.current.params.id;
            return tempApi;
        },
        fetch() {
            let companyId = this.$router.history.current.params.id;
            let fetchCompanyApi = this.fetchCompany(companyId);
            // let fetchCompanyApi = (this.$store.getters.getRole=='operation'||this.$store.getters.getRole=='developer')?:this.fetchCompanyWithRestrict(companyId);
            let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
            let fetchRenewalStatusApi = this.fetchRenewalStatus(companyId);
            let fetchLatestPaymentStatusApi = this.fetchPaymentStatus(companyId);
            let fetchClientPICApi = this.fetchClientPIC(companyId);
            let fetchRefundApi = this.fetchRefund();
            let fetchAttachmentApi = this.fetchAttachment();
            let fetchSpecialRemarkApi = this.fetchSpecialRemark();
            let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory(companyId);
            let fetchCompanyRefundApi = this.fetchCompanyRefund(companyId);
            this.$axios.all([
                this.$api.fetch(fetchCompanyApi),
                this.$api.fetch(fetchSubscriptionApi),
                this.$api.fetch(fetchClientPICApi),
                this.$api.fetch(fetchRenewalStatusApi),
                this.$api.fetch(fetchLatestPaymentStatusApi),
                this.$api.fetch(fetchRefundApi),
                this.$api.fetch(fetchAttachmentApi),
                this.$api.fetch(fetchSpecialRemarkApi),
                this.$api.fetch(fetchCompanySubscriptionHistoryApi),
                this.$api.fetch(fetchCompanyRefundApi),
                this.$api.fetch(this.fetchExistingClientSurvey()),
            ]);
        },

        fetchOld() {
            this.role = this.$store.getters.getRole;
            this.api.isLoading = true;
            this.company = this.$store.getters.getCompany;
            this.latestPaymentStatusForm = null;
            this.latestRenewalForm = (this.company.vision_latest_renewal_form) ? JSON.parse(this.company.vision_latest_renewal_form) : null;

            this.latestPaymentForm = (this.company.vision_latest_payment_status_form) ? JSON.parse(this.company.vision_latest_payment_status_form) : null;
            let apiSubscription = this.fetchSubscription();
            let apiUser = this.fetchUser();
            let apiLog = this.fetchLog();
            let apiRenewal = this.fetchRenewalStatus();
            this.$axios.all([
                this.$api.fetch(apiSubscription),
                this.$api.fetch(apiUser),
                this.$api.fetch(apiLog),
                this.$api.fetch(apiRenewal),
            ]).then(() => {

            });

        },
        fetchSubscription(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "get";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/subscription/" + companyId + '/latest';
            return tempApi;
        },
        fetchAttachment() {
            this.isLoadingAttachment = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/attachment/company/' + this.$router.history.current.params.id;
            return tempApi;
        },
        fetchExistingClientSurvey() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/survey/existing?id="+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchUser()
        {
            let tempApi = this.$_.clone(this.api);

            tempApi.method = "get";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/company/' + this.company.id + "?user=true&staff=true";
            return tempApi;
        },
        fetchLog() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "get";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/log/' + this.company.id;
            return tempApi;
        },
        fetchRenewalStatus(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/form/page/6?companyId=" + companyId + "&formType=Renewal";
            return tempApi;
        },
        fetchPaymentStatus(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/form/latest/" + companyId + "?formType=Payment";
            return tempApi;
        },

        scrollDown() {
            setTimeout(this.goDown, 200);
        },
        goDown() {
            window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        },
        displayAction() {
            this.actionDrop = !this.actionDrop;
        },
        showNotAllowed() {
            this.notAllowedAction = true;
        },
        actions(actionName) {
            if (actionName == "Followup") {
                this.$router.push({ name: 'PagePICFollowupCreate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }

            if (actionName === "updateRemark") {
                this.$router.push({ name: 'PageCompaniesRemark', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "AAEMinuteRecord") {
                this.$router.push({ name: 'PageMinuteRecordList', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == 'AddNewUser') {
                this.$router.push({ name: 'PageUserCreate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "ActivateSubscription") {
                this.$router.push({ name: 'PageActivateSubscription', params: { id: this.$router.history.current.params.id } });
                // this.$router.push({name:'PageManualDetail',params:{'id':"YesYouCanActivateSub"}});
                return 1;
            }
            if (actionName == 'SendEmail') {
                this.$router.push({ name: "PageSendEmail", params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "CancelSubscription") {
                this.$router.push({ name: 'PageCancelSubscription', params: { id: this.$router.history.current.params.id } });
                // this.$router.push({name:'PageManualDetail',params:{'id':"YesYouCanCancelSub"}});
                return 1;
            }
            if (actionName == "AddMinutes") {
                this.$router.push({
                    name: 'PageAAEMinutesAdd',
                    params: {
                        id: this.$router.history.current.params.id
                    },
                })
                return 1;
            }
            if (actionName == "RequestRefund") {
                this.$router.push({
                    name: 'PageRefundForm',
                    params: {
                        id: this.$router.history.current.params.id,
                    },
                })
                return 1;
            }
            if (actionName == "Log") {

                this.$router.push({
                    name: 'MockupCompanyLogs', params: { id: this.$router.history.current.params.id }
                });
                return 1;
            }
            if (actionName == "SendWhatssap") {
                this.$router.push({
                    name: 'PageWhatssapCreate',
                    params: {
                        'id': this.$router.history.current.params.id,
                    }
                })
                return 1;
            }
            if (actionName == "SwapUserCompany") {
                this.$router.push({ name: 'PageSwapUser', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "PIC") {
                this.$router.push({
                    name: 'PagePicUpdate'
                    , params: { id: this.$router.history.current.params.id }
                });
                return 1;
            }
            if (actionName == "Edit") {
                this.$router.push({ name: 'PageCompaniesUpdate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName === "EditUser") {
                this.$router.push({ name: 'PageUserUpdate', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "RemoveCredit") {
                this.$router.push({ name: 'PageCreditCardDelete', params: { id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "UploadReceipt") {
                this.$router.push({ name: 'PageReceiptUpload', params: { 'attachment': "Receipt", id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "UploadInvoice") {
                this.$router.push({ name: 'PageReceiptUpload', params: { 'attachment': 'Invoice', id: this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "ViewTask") {
                this.$router.push({ name: 'PageTaskList', params: { 'id': this.$router.history.current.params.id } });
                return 1;
            }
            if (actionName == "ViewAttachment") {
                this.$router.push({ name: 'PageAttachmentFormCompany', params: { 'id': this.$router.history.current.params.id } })
                return 1;
            }
            if (actionName == "Payment") {
                this.$store.commit('updateForm', this.latestPaymentForm);
            }
            this.$store.commit("updateAction", actionName);
            this.$router.push({ name: 'CompaniesForm', params: { id: this.$router.history.current.params.id } });
        },
        close() {
            this.remark = false;
        },
        closeDetail() {
            this.$store.dispatch('clearCompany')
            this.$router.push({ name: 'Companies' });
        },
        readAllSubscription() {
            this.$router.push({ name: 'CompaniesSubscription', params: { 'id': this.$router.history.current.params.id } });
        },
        readAllRenewalUpdate() {
            this.$router.push({ name: 'CompaniesRenewalUpdate', params: { 'id': this.$router.history.current.params.id } });
        },
        readAllPaymentUpdate() {
            this.$router.push({
                name: 'CompaniesPaymentUpdate', params: {
                    'id': this.$router.history.current.params.id
                }
            })
        },
        closeNotAllowed() {
            this.$router.go(-1);
        },
        closeNotAllowedAction() {
            this.notAllowedAction = false;
        },
        cancelUpdateRefundStatus() {
            this.isPendingRefundStatus = false;
            location.reload();
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        convertTimeToDay(time) {
            return this.$moment(time).format("LL");
        },
        validateInputChangeVerification() {
            this.isPendingVerification = true;
        },
        validateInputChangeRefundStatus(refundId, refundStatus) {
            this.tempRefundStatus = refundStatus;
            this.tempRefundId = refundId;
            this.isPendingRefundStatus = true;
        },
        updateCompanyVerification() {
            this.isPendingVerification = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/company/verification/' + this.company.verification;
            tempApi.params = {
                'id': this.company.companies_id,
            };
            this.isLoadingUpdateVerification = true;
            this.$api.fetch(tempApi);
        },
        updateRefundStatus() {
            this.isPendingRefundStatus = false;
            this.isLoadingRefund = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/refund/id/" + this.tempRefundId;
            tempApi.params = {
                'status': this.tempRefundStatus,
            }
            this.$api.fetch(tempApi);
        },
        cancelUpdateCompanyVerification() {
            this.isPendingVerification = false;
            location.reload();
        },
        checkIfCanAccessSubscriptionHistory() {
            if (this.$store.getters.getFeatures.subscriptionHistory.status == 1 || (this.$store.getters.getFeatures.subscriptionHistory.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
        checkIfCanAccessOnboarding() {
            if (this.$store.getters.getFeatures.onboarding.status == 1 || (this.$store.getters.getFeatures.onboarding.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
        checkIfCanAccessCompanyRefund() {
           if(this.$store.getters.getFeatures.refund.status==1 || (this.$store.getters.getFeatures.refund.beta==1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
           }
           return false;
        },
        checkIfCanAccessCompanyInvoice(){
            if(this.$store.getters.getFeatures.invoice.status==1 || (this.$store.getters.getFeatures.invoice.beta==1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
        checkIfCanAccessBonusOffer() {
            if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
        checkIfCanAccessCompanyHRDC() {
           if(this.$store.getters.getFeatures.hrdc.status==1 || (this.$store.getters.getFeatures.hrdc.beta==1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
           }
           return false;
        },
        checkIfCanAccessCompanySales() {
           if(this.$store.getters.getFeatures.sales.status==1 || (this.$store.getters.getFeatures.sales.beta==1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
           }
           return false;
        },
        checkIfCanAccessCompanyAAE() {
           if(this.$store.getters.getFeatures.aae.status==1 || (this.$store.getters.getFeatures.aae.beta==1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
           }
           return false;
        },
        checkIfCanAccessCompanyFinance() {
            if(this.$store.getters.getFeatures.survey.status==1 || (this.$store.getters.getFeatures.survey.beta==1 && this.$store.getters.getRole.includes('developer'))) {

                return true;
            }
            return false;
        },
        checkIfCanAccessCompanyFunnel() {
           if(this.$store.getters.getFeatures.funnel.status==1 || (this.$store.getters.getFeatures.funnel.beta==1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
           }
           return false;
        }

       
        // readAllPaymentUpdate()
        // {
        //     this.$router.push({name:'CompaniesPaymentUpdate',params:{
        //         'companyId':this.company.company_id
        //     }});
        // }

    }
}
</script>