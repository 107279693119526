<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
           v-if="!api.isLoading">
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Add financial detail
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                    <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="form.fye"
                                        dense 
                                        outlined
                                        label="Financial year end">
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="form.revenue"
                                        dense 
                                        outlined
                                        label="Revenue (RM'000)">
                                    </v-text-field>
                                </div>
                                <div>
                                    <v-text-field
                                        dense 
                                        outlined
                                        v-model="form.pbt"
                                        label="Profit before tax (RM'000)">

                                    </v-text-field>
                                </div>
                                <div>
                                    <v-text-field
                                    v-model="form.pat"
                                    label="Profit after tax (RM'000)"
                                    dense 
                                    outlined>
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ASuccessWithoutReload,
},
    computed: mapState({
    //
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
            fye:null,
            revenue:null,
            pat:null,
            pbt:null,
        },
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="addFinanceSurvey") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update detail";
            }
           
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    },
    methods: {
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            this.$api.fetch(this.createFinancial());
        },
        cancelSubmit(){
            this.isPending = false;
        },
        createFinancial(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/survey/finance/"+this.id;
            let formData = new FormData;
            formData.append('fye',this.form.fye);
            formData.append('revenue',this.form.revenue);
            formData.append('pat',this.form.pat);
            formData.append('pbt',this.form.pbt);
            tempApi.params = formData;
            return tempApi
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>