var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),_c('v-dialog',{attrs:{"height":"700","width":"700"},on:{"click:outside":function (){
            this$1.isUpdating = false;
            this$1.isUpdatingId = null;
        }},model:{value:(_vm.isUpdating),callback:function ($$v) {_vm.isUpdating=$$v},expression:"isUpdating"}},[(_vm.isUpdating)?_c('ComponentUpdateInvoiceCompany',{attrs:{"id":this.isUpdatingId},on:{"finish":function($event){return _vm.fetch()},"close":function (){
            this$1.isUpdating = false;
            this$1.isUpdatingId=null;
        }}}):_vm._e()],1),_c('v-dialog',{attrs:{"height":"700","width":"700"},on:{"click:outside":function (){
            this$1.isAdding = false;
        }},model:{value:(_vm.isAdding),callback:function ($$v) {_vm.isAdding=$$v},expression:"isAdding"}},[(_vm.isAdding)?_c('ComponentAddInvoiceCompany',{attrs:{"id":this.$router.history.current.params.id},on:{"finish":function($event){return _vm.fetch()},"close":function (){
            this$1.isAdding = false;
            this$1.isAddingCompanyId=null;
        }}}):_vm._e()],1),(_vm.data!=null && !_vm.api.isLoading)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-h6 ",staticStyle:{"font-weight":"600"}},[_vm._v(" Clien invoice records ")]),_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.openNewModal}},[_vm._v(" Add ")])],1)]),_c('div',_vm._l((this.data),function(item,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"pb-3"},[_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"d-flex justify-start"},[_c('p',{staticClass:"text-h7",staticStyle:{"font-weight":"600"}},[_vm._v(" Year "+_vm._s(_vm.convertTimeToYear(item.send_date))+" ")])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" Invoice no. : ")]),_c('p',[_vm._v(" "+_vm._s(item.invoice_no)+" ")])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" Client request invoice : ")]),_c('p',[_vm._v(" "+_vm._s((item.request_invoice!=null)?item.request_invoice:"No")+" ")])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" Invoice to name : ")]),_c('p',[_vm._v(" "+_vm._s((item.requester_name!=null)?item.requester_name:"-")+" ")])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" Invoice to address : ")]),_c('p',[_vm._v(" "+_vm._s((item.requester_address!=null)?item.requester_address:"-")+" ")])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" Invoice send date : ")]),_c('p',[_vm._v(" "+_vm._s(_vm.convertTimeToDay(item.send_date))+" ")])]),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openUpdateModal(item.id)}}},[_vm._v(" Update ")])],1)])])],1)}),1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }