<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-dialog
            v-model="isUpdating" height="700" width="700" @click:outside="()=>{
                this.isUpdating = false;
                this.isUpdatingId = null;
            }">
        <ComponentUpdateCompanySales
            @finish="fetch()"
            v-if="isUpdating"
            @close="()=>{
                this.isUpdating = false;
                this.isUpdatingId=null;
            }"
            :id="this.isUpdatingId"></ComponentUpdateCompanySales>
        </v-dialog>

        <v-dialog
            v-model="isAdding" height="700" width="700" @click:outside="()=>{
                this.isAdding = false;
            }">
            <ComponentAddCompanySales
            @finish="fetch()"
            v-if="isAdding"
            @close="()=>{
                this.isAdding = false;
                this.isAddingCompanyId 
            }"
            :companyId="this.$router.history.current.params.id"
            />
        </v-dialog>
        <div
            v-if="data!=null && !api.isLoading">
            <div
                class="d-flex justify-space-between">
                <div
                    class="text-h6 " style="font-weight:600">
                    Sales
                </div>
    
                <div>
                    <v-btn color="primary" @click="openNewModal">
                        Add
                    </v-btn>
                </div>
            </div>
            <div>
                <v-row
                    v-for="(item,index) in this.data"
                    :key="index">
                    <v-col
                    class="pb-3"
                    ><br>
                    <div
                        class="my-2">
                        <div
                            class="d-flex justify-space-between">
                            <p class="text-h8">
                                Salesperson :
                            </p>
                            <p >
                            {{ (item.salesperson!=null)?item.salesperson:'-' }} 
                            </p>
                        </div>
                        <div
                            class="d-flex justify-space-between">
                            <p
                                class="text-h8">
                                Team :
                            </p>
                            <p>
                                {{ (item.salesperson_team)?item.salesperson_team:'-' }}
                            </p>
                        </div>
                       
                        <div
                            class="d-flex justify-space-between">
                            <p
                                class="text-h8">
                                Name :
                            </p>
                            <p>
                                {{ (item.salesperson_name)?item.salesperson_name:'-' }}
                            </p>
                        </div>                       
                        <div
                            class="d-flex justify-space-between">
                            <p
                                class="text-h8">
                                Sales Funnel :
                            </p>
                            <p>
                                {{ (item.sales_funnel)?item.sales_funnel:'-' }}
                            </p>
                        </div>
                        <div
                            class="d-flex justify-end">
                            <v-btn
                                @click="openUpdateModal(item.id)"
                                color="primary">
                                Update
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                </v-row>
            </div>

        </div>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentAddCompanySales from '../../components/form/ComponentAddCompanySales.vue';
import ComponentUpdateCompanySales from '../../components/form/ComponentUpdateCompanySales.vue';
export default {
    components:{
    ComponentAddCompanySales,
    ComponentUpdateCompanySales
},
    computed: mapState({
    //
    }),
    props:[
        'companyId'
    ],
    data: () => ({
        isAdding:null,
        isAddingCompanyId:null,
        isUpdatingId:null,
        isUpdating:false,
        data:null,
        dataHeader:[
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getCompanySalesByCompany") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.isAdding = false;
            this.isAddingCompanyId = null;
            this.isUpdating = false;
            this.isUpdatingId = null;
            let fetchCompanySalesApi = this.fetchCompanySales();
            this.$api.fetch(fetchCompanySalesApi);
        },
        fetchCompanySales() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/sales/company/detail/"+this.companyId;
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeToDay(time) {
            return this.$moment(time).format("LL");
        },
        openNewModal() {
            this.isAddingCompanyId = this.$router.history.current.params.id;
            this.isAdding = true;
        },
        openUpdateModal(id) {
            this.isUpdatingId = id;
            this.isUpdating = true;
        }
    }
}
</script>